<template>
	<div class="main-content">
		<div class="meunTop">
			<div class="meunLine"></div>
			<div class="meunTitle">积分结算配置</div>
		</div>
		<div class="flex">
			<div></div>
			<el-button @click="addshow=true;addtype=1;resource='1'">添加配置</el-button>
		</div>
		<div class="table-content">
			<el-table :cell-style="{ textAlign: 'center' }" :header-cell-style="{textAlign: 'center',backgroundColor:'#F5F7FA',color:'#333'}" :border="true" :data="data" style="width: 100%">
				<el-table-column prop="userType" label="用户类型" width="300">
                     <template slot-scope="scope">
                        <span >{{scope.row.userType == 1 ? '分销员/游客' : '供应商'}}</span>
                    </template>
                </el-table-column>
				<el-table-column prop="userName" label="用户名称"></el-table-column>
				<el-table-column prop="settlementDay" label="结算周期（天）"></el-table-column>
                <el-table-column prop="updateTime" label="操作时间" ></el-table-column>
				<el-table-column  label="操作" width="300">
					<template style="display: flex;" slot-scope="scope">
						<el-button @click.native.prevent="updataclick(scope.$index, data)" type="text" size="small">
							修改
						</el-button>
						<el-button v-if="scope.row.allowDelete == 1" @click.native.prevent="deleteList(scope.$index, data)" type="text" size="small">
							删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 新增的弹窗 -->
		<el-dialog :visible.sync="addshow" width="700px" :before-close="handleClose" :title="addtype == 1 ? '添加' : '修改'">
			<el-form class="form_box" :model="sizeForm" label-position='right' ref="sizeForm"  size="small">
				<el-form-item  >
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:130px;text-align:right;margin-right:10px;"><span style="color:red">*</span>用户类型：</p>
							<el-select v-model="sizeForm.userType" clearable style="width:350px;"  @change="chooseType($event)" >
								<el-option v-for="item in userData" :key="item.type" :label="item.typeName" :value="item.type"> </el-option>
							</el-select>
						</div>
					</template>
				</el-form-item>
				<el-form-item  >
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:130px;text-align:right;margin-right:10px;"><span style="color:red">*</span>用户名称：</p>
							<el-select v-model="sizeForm.userId" clearable style="width:350px;">
								<template v-for="item in users">
									<el-option  :key="item.userId" :label="item.username" :value="item.userId"> </el-option>
								</template>
							</el-select>
						</div>
					</template>
				</el-form-item>
				<el-form-item  >
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:130px;text-align:right;margin-right:10px;"><span style="color:red">*</span>结算周期：</p>
							<el-input class="numberInput" min="0" type="number" style="width:100px;" oninput="if(value.length>3)value=value.slice(0,3)" @keyup.native="keyVerify($event)"  v-model="sizeForm.settlementDay"></el-input>
							<span class="number_tip"><span style="font-size:14px;color:#333;">天</span>商品收货后，结算该商品积分的时效</span>
						</div>
					</template>
				</el-form-item>
				<el-form-item size="large" style="display:flex;justify-content:center;">
					<el-button v-if="addtype == 2" type="primary"  @click.native.prevent="onUpdata('sizeForm')">保存</el-button>
					<el-button v-if="addtype == 1" type="primary"  @click.native.prevent="submitForm('sizeForm')">保存</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

		<div class="">
			<el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage"
				:page-count="pageCount" :page-size="pageSize" layout="total, prev, pager, next" :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		integralsettlementconfList,integralsettlementconfAdd,integralsettlementconfDelete,integralsettlementconfUpdate,integralsettlementconfParams
	} from "@/api/platform/index";
	export default {
		name: "proportionSet",
		data() {
			return {
				resource: "1",
				data: [],
                userData:[],
                supplierList:[],
				addshow: false,
				sizeForm: {
                    id:'',
                    settlementDay:'',
                    userId:'',
                    userType:'',
				},
                users:[],
				page: 1,
				pageSize: 10,
				total: 0, //总数据
				currentPage: 0, //当前页页数
				pageCount: 0, //总页数
				addtype: '',
				id: 0,
			};
		},
		mounted() {
			let that = this;
			that.list();
            that.getUser();
		},
		created() {
		},
		methods: {
			//获取列表
			list() {
				integralsettlementconfList({
					page: this.page,
					size: this.pageSize,
				}).then(res => {
					this.data = res.data.records;
					this.total = res.data.total;
					this.currentPage = res.data.current;
					this.pageCount = res.data.pages;
					if (res.data.current > res.data.pages && res.data.pages != 0) {
						this.page = res.data.pages;
						this.list();
					}
				})
			},

            // 获取比例用户列表
            getUser(){
                integralsettlementconfParams({}).then(res => {
					if (res.code == 200) {
                    	this.userData = res.data.params;
                    }
                })
            },

			//分页
			handleCurrentChange(val) {
				this.page = val;
				this.list();
			},

            // 用户类型选择	用户名称联动
			chooseType(e){
				let that = this;
				if(e != null && e.toString().length > 0){
                    that.sizeForm.userType = e;
                    that.users = that.userData[0].users;
                    that.sizeForm.userType = e;
				}else{
					that.sizeForm.userId = '';
					that.users = [];
				}
			},

			//编辑提交
			onUpdata(formName){
                let that = this;
				if(that.addshow){
					that.$refs[formName].validate((valid) => {
						if (valid) {
						    if(that.$refs[formName].model.settlementDay.toString().length > 0 && that.$refs[formName].model.settlementDay > 0){
								integralsettlementconfUpdate({
									id: that.$refs[formName].model.id,
                                    settlementDay: that.$refs[formName].model.settlementDay,
                                    userId: that.$refs[formName].model.userId,
                                    userType: that.$refs[formName].model.userType,
								}).then(res=>{
									if (res.code == 200) {
										that.$message.success('修改成功');
										that.sizeForm = {
                                            id: '',
                                            settlementDay: '',
                                            userId: '',
                                            userType: '',
										}
										that.addshow = false
										that.page = 1
										that.list();
									} else {
										that.$message.error(res.msg);
									}
								}).catch(err => {
									that.$message.error(err.msg);
								})
							}else{
                                that.$message.warning('结算周期不能为空且大于0');
                            }
						} else {
							return false;
						}
					});
				}
			},

			//删除
			deleteList(cur, tab) {
				this.$confirm('您确定要删除该积分结算配置?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					integralsettlementconfDelete(tab[cur].id).then(res => {
						if (res.code == 200) {
							this.$message.success('删除成功');
							this.page = 1
							this.list();
						} else {
							this.$message.error(res.msg);
						}
					})
				})
			},
			
			//关闭提醒
			handleClose(done) {
				this.sizeForm = {
					id: '',
					settlementDay: '',
					userId: '',
					userType: '',
				}
				this.addshow = false
			},

			//编辑按钮点击
			updataclick(i,tab){
                let that = this;
                that.users = that.userData[0].users;
				that.sizeForm = {
                    id:tab[i].id,
                    settlementDay:tab[i].settlementDay,
                    userId:tab[i].userId,
                    userType: tab[i].userType
				}
				that.addtype = 2
				that.addshow = true
			},

			//添加提交
			submitForm(formName) {
                let that = this;
				if(that.addshow){
					that.$refs[formName].validate((valid) => {
						if (valid) {
							if(that.$refs[formName].model.userType != null && that.$refs[formName].model.userType.toString().length > 0){
								if(that.$refs[formName].model.settlementDay.toString().length > 0 && that.$refs[formName].model.settlementDay > 0){
									integralsettlementconfAdd({
										settlementDay: that.$refs[formName].model.settlementDay,
										userId: that.$refs[formName].model.userId,
										userType: that.$refs[formName].model.userType,
									}).then(res=>{
										if(res.code == 200){
											that.$message.success('添加成功');
											that.sizeForm = {
												id: '',
												settlementDay: '',
												userId: '',
												userType: '',
											}
											that.addshow = false
											that.page = 1
											that.list();
										}else{
											that.$message.error(res.msg);
										}
									}).catch(err => {
										that.$message.error(err.msg);
									})
								}else{
									that.$message.warning('结算周期不能为空且大于0');
								}
							}else{
								that.$message.warning('请选择用户类型');
							}
						} else {
							return false;
						}
					});
				}
			},
		}
	}
</script>

<style scoped>
	.table-content {margin-top: 20px;}
	.active {color: #049EC4;}
	.form_box{padding:0 50px;}
	.avatar{width:150px;}
	.photo{width:150px;height:150px;border:1px solid #eee;display:flex;align-items: center;justify-content:center;font-size:40px;color:#ccc;}
	.flex{display:flex;align-items:center;justify-content:space-between;}

	.meunTop{display:flex;align-items:center;margin-bottom:20px;}
	.meunLine{width:3px;height:20px;background-color:#FFA218;}
	.meunTitle{font-size:20px;margin-left:10px;}
    .el-input__inner{
        -webkit-appearance: none !important;
        -moz-appearance: textfield !important;
    }
    .number_tip{
        font-size: 12px;
        margin-left: 10px;
        color: #909090;
    }
</style>
